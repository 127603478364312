<template>
  <div class="product-listing">
    <AdvertisingBanner class="col-12 my-30 mt-lg-0" />
    <BestSellers />
    <CategoryPageContent />
    <section class="bottom-txt">
      <div v-html="category.description"></div>
    </section>
  </div>
</template>

<script>
import CategoryPageContent from "@/base/core/components/CategoryPageContent";
import AdvertisingBanner from "@/base/core/components/AdvertisingBanner";
import BestSellers from "@/base/core/components/BestSellers";
export default {
  name: "ProductListingV2",
  components: {
    BestSellers,
    CategoryPageContent,
    AdvertisingBanner,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
  },
};
</script>

