<template>
  <div class="content-filter">
    <div class="cst-pagination-bar">
      <label
        >Pagina<span>{{ currentPage }}</span
        >van<span>{{ Math.ceil(totalProds / prodsPerPage) }}</span>
      </label>
      <b-pagination
        :total-rows="totalProds"
        :per-page="prodsPerPage"
        :value="currentPage"
        @change="changePage"
        class="cst-pagination"
        aria-controls="cat-item"
      >
        <template #page="{ page }">
          <b-link class="page-link" :to="goToPage(page)">
            {{ page }}
          </b-link>
        </template>
      </b-pagination>
    </div>
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select :value="prodsPerPage" @change="changePerPage">
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="36">36</option>
          </select>
        </div>
      </div>
      <div class="select-wrapper">
        <b-form-select
          id="sorting"
          :options="selectOptions"
          class="select"
          @change="changeSorting"
          :value="sorting"
        />
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
export default {
  name: "CategoryPageContentFilter",
  mounted() {
    const { page } = this.$route.query;
    const path = "/" + this.category.url_path;
    const query = { ...this.$route.query };
    if (page && this.totalPages < parseInt(page)) {
      delete query["page"];
    }
    const pageSize = config.products?.perPage;
    const perPage = this.$store.getters["category/getProductsPerPage"];
    if (perPage != pageSize) query["page-size"] = perPage;

    this.$router.replace({ path, query });
  },
  computed: {
    selectOptions() {
      const sortOpt = [];
      Object.keys(config.sortingOptions).forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    sorting() {
      return this.$store.getters["category/getSorting"];
    },
    currentPage() {
      return this.$store.getters["category/getCurrentPage"];
    },
    totalProds() {
      return this.$store.getters["category/getTotalProducts"];
    },
    prodsPerPage() {
      return this.$store.getters["category/getProductsPerPage"];
    },
    totalPages() {
      return this.$store.getters["category/getTotalPages"];
    },
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
  },
  methods: {
    changePage(pageNo) {
      this.$store.commit("category/setCurrentPage", pageNo);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (pageNo != 1) query["page"] = pageNo;
      else delete query["page"];

      this.$router.replace({ path, query });
    },
    changePerPage(event) {
      const perPage = +event.target.value;
      const { path } = this.$route;
      const query = { ...this.$route.query };

      const pageSize = config.products?.perPage;

      if (perPage != pageSize) query["page-size"] = perPage;
      else delete query["page-size"];
      delete query["page"];

      this.$router.replace({ path, query });

      this.$store.commit("category/setProductsPerPage", perPage);
    },
    changeSorting(sortVal) {
      this.$store.commit("category/setSorting", sortVal);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      const { sortDefault } = config;
      if (config.sortingOptions[sortVal] && sortVal !== sortDefault) {
        query["sort"] = sortVal;
      } else {
        delete query["sort"];
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
    goToPage(page) {
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      return { path, query };
    },
  },
  data: () => ({}),
};
</script>


