<template>
  <section class="page-content">
    <CategoryPageContentFilter />
    <FilteredProducts />
    <CategoryPageContentFilter />
  </section>
</template>

<script>
import CategoryPageContentFilter from "@/base/core/components/CategoryPageContentFilter";
import FilteredProducts from "@/base/core/components/FilteredProducts";
export default {
  name: "CategoryPageContent",
  components: {
    CategoryPageContentFilter,
    FilteredProducts,
  },
};
</script>


