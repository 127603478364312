<template>
  <section class="best-sellers">
    <div class="best-seller--category">{{ category.name }}</div>
    <span v-if="this.bestsellers.length > 0" class="section-title">{{
      $t("bestsellers_for_you")
    }}</span>
    <div v-if="this.bestsellers.length > 0" class="row">
      <div
        class="col-6 col-md-3 position-relative best-seller--card-holder"
        v-for="product of this.bestsellers.slice(0, 4)"
        :key="product.sku"
      >
        <b-link
          v-if="
            $store.getters['user/isProductInWishlist'](product.sku) &&
            isLoggedIn
          "
          class="add-to-wishlist"
          @click="
            onClickWishListIcon(
              product.sku,
              product.__typename,
              product.url_key
            )
          "
        >
          <div
            class="heart"
            v-if="$store.getters['user/isProductInWishlist'](product.sku)"
          >
            <img src="@/base/assets/heart.png" alt="heart" />
          </div>
          <i class="lnr lnr-heart" v-else />
        </b-link>
        <b-link class="bestsellers-card" :to="`/` + product.url_key">
          <div
            class="bestsellers-card--img"
            :style="{ backgroundImage: `url(${product.image.medium})` }"
          >
            <div class="actionbuttons">
              <span class="sale-txt" v-if="product.sale == 1">Sale</span>
              <span class="new-txt" v-if="product.new == 1">Nieuw</span>
            </div>
          </div>
          <span class="bestsellers-card--product-name">{{ product.name }}</span>

          <span
            v-if="
              product.price_range.minimum_price.final_price.value ==
              product.price_range.maximum_price.final_price.value
            "
            class="d-block bestsellers-card--price"
            >&euro;{{
              product.price_range.minimum_price.final_price.value
                .toFixed(2)
                .replace(".", ",")
            }}</span
          >
          <span
            v-if="
              product.price_range.minimum_price.final_price.value !=
              product.price_range.maximum_price.final_price.value
            "
            class="d-block bestsellers-card--price"
            >&euro;{{
              product.price_range.minimum_price.final_price.value
                .toFixed(2)
                .replace(".", ",")
            }}
            - &euro;{{
              product.price_range.maximum_price.final_price.value
                .toFixed(2)
                .replace(".", ",")
            }}</span
          >

          <span
            class="d-block bestsellers-card--sale-comment"
            v-if="product.price_range.minimum_price.discount.percent_off > 0"
            >{{ $t("discount") }}
            {{
              product.price_range.minimum_price.discount.percent_off.toFixed(0)
            }}%</span
          >
          <span
            class="d-block bestsellers-card--sale-comment"
            style="visibility: hidden"
            v-if="product.price_range.minimum_price.discount.percent_off == 0"
            >{{ $t("discount") }}
            {{
              product.price_range.minimum_price.discount.percent_off.toFixed(0)
            }}%</span
          >
        </b-link>
      </div>
    </div>
  </section>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import config from "@config";

export default {
  name: "BestSellers",
  methods: {
    onClickWishListIcon(productId, typename, url_key) {
      if (this.$store.getters["user/isProductInWishlist"](productId) == true) {
        this.$store.dispatch("user/setProductWishlistStatus", {
          sku: productId,
          parentSku: null,
        });
      } else {
        if (typename == "SimpleProduct") {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: productId,
            parentSku: null,
          });
        } else {
          const msg = {
            type: "danger",
            title: this.$t("wishlist error"),
            text: this.$t("wishlist_select_options"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push("/" + url_key);
        }
      }
    },
    async loadBestsellers() {
      if (!isServer) {
        if (config.helloRetail && config.helloRetail.enabled) {
          const hello_retail_id = this.$cookies.get("hello_retail_id");
          await this.$store.dispatch("category_local/loadBestsellerProducts", {
            category: this.category.name,
            hello_retail_id,
          });
        } else {
          await this.$store.dispatch("category/loadBestsellerProducts");
        }
      }
    },
  },
  computed: {
    bestsellers() {
      var bestsellers = [];
      if (config.helloRetail && config.helloRetail.enabled) {
        bestsellers =
          this.$store.getters["category_local/getBestsellerProducts"];
      } else {
        bestsellers = this.$store.getters["category/getBestSellersSimple"];
      }
      return bestsellers;
    },
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    "category.name": {
      handler(newCategory, oldCategory) {
        if (newCategory && oldCategory && newCategory != oldCategory)
          this.loadBestsellers();
      },
      deep: true,
    },
  },
  async mounted() {
    this.loadBestsellers();
  },
};
</script>


