<template>
  <BaseLayout>
    <Breadcrumbs />
    <div v-if="category.brand == null" class="category">
      <CategoryOverview
        v-if="category.is_anchor == 0"
        :category="category"
        :categories="categories"
      />
      <div class="container" v-if="category.is_anchor == 1">
        <div class="row">
          <ProductFilter class="col-lg-3" />
          <ProductsListingV2 class="col-lg-9" />
        </div>
        <div v-if="categoryForm != null">
          <dynamic-forms :category_id="category.id" type="category" />
        </div>
      </div>
    </div>
    <div v-if="category.brand != null">
      <div class="container clearfix">
        <div class="category_description" v-html="category.description"></div>
        <div class="brand_logo">
          <img :src="category.brand.logo" />
        </div>
      </div>
      <CategoryOverview :category="category" />
      <div class="container" v-if="category.is_anchor == 1">
        <div class="row">
          <ProductFilter class="col-lg-3" />
          <ProductsListingV2 class="col-lg-9" />
        </div>
        <div v-if="categoryForm != null">
          <dynamic-forms
            :category_id="category.id"
            type="category"
            :categories="categories"
          />
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import config from "@config";
import BaseLayout from "@/base/core/components/common/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import ProductFilter from "@/base/core/components/ProductFilter";
import CategoryOverview from "@/base/core/components/CategoryOverview";
import ProductsListingV2 from "@/base/core/components/ProductListingV2";
import DynamicForms from "@/base/core/components/DynamicForms";
export default {
  name: "CategoryOverviews",
  components: {
    BaseLayout,
    Breadcrumbs,
    ProductFilter,
    ProductsListingV2,
    CategoryOverview,
    DynamicForms,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    categoryId() {
      return this.$store.getters["category/getCurrentCategoryId"];
    },
    categories() {
      return this.category.children;
    },
    categoryForm() {
      return this.$store.getters["forms/getCategoryForm"];
    },
    gtmProducts() {
      return this.$store.getters["category/getGtmProducts"];
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        const query = {};
        const pageSize = config.products?.perPage;
        const perPage = this.$store.getters["category/getProductsPerPage"];
        if (perPage != pageSize) query["page-size"] = perPage;
        this.$router.push({ path: to.path, query: query });
      }
    },
    gtmProducts() {
      if (this.$gtm.enabled()) {
        if (this.gtmProducts.length > 0) {
          window.dataLayer.push({
            event: "eec.impressionView",
            ecommerce: {
              impressions: this.gtmProducts,
            },
          });
        }
      }
    },
  },
  destroyed() {
    this.$store.commit("category/setLoadProducts", false);
  },
  methods: {
    setBreadcrumb() {
      const breadcrumbs = this.category.breadcrumbs;
      let currentName = "undefined";
      if (typeof this.category.name == "string") {
        currentName = this.category.name;
      }
      const bcrumb = { current: currentName, routes: [] };
      if (breadcrumbs != null) {
        breadcrumbs.sort((a, b) => {
          if (a.category_level < b.category_level) return -1;
          if (a.category_level > b.category_level) return 1;
          return 0;
        });
        let path = "";
        breadcrumbs.forEach((element) => {
          if (path.length > 0) {
            path = path + "/";
          }
          path = path + element.category_url_key;
          let name = "undefined";
          if (typeof element.category_name == "string") {
            name = element.category_name;
          }
          const bc = {
            name: name,
            route_link: "/" + path,
          };
          bcrumb.routes.push(bc);
        });
      }
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.category
            ? this.category.meta_title ?? this.category.name ?? ""
            : "",
        },
        {
          name: "keywords",
          content: this.category ? this.category.meta_keywords ?? "" : "",
        },
        {
          name: "description",
          content: this.category ? this.category.meta_description ?? "" : "",
        },
      ],
      title: this.category.meta_title ?? this.category.name ?? "",
    };
  },
  mounted() {
    this.setBreadcrumb();
  },
};
</script>


