<template>
  <div class="container">
    <CatBanner />
    <b-row v-if="category.children_count > 0">
      <b-col v-for="(category, i) of categories" :key="`${category} ${i}`">
        <b-link class="category-link" :to="`/` + category.url_path">
          <div class="category-box">
            <div class="img">
              <b-img-lazy
                v-if="category.image != null"
                :src="category.image"
                :alt="category.name"
                fluid
              ></b-img-lazy>
            </div>
            <div class="text">
              <p>{{ category.name }}</p>
            </div>
          </div>
        </b-link>
      </b-col>
    </b-row>
    <b-row v-if="category.children_count == 0 && category.brand == null">
      <div style="width: 100%; text-align: center">
        {{ $t("no_products_found") }}
      </div>
    </b-row>
  </div>
</template>
<script>
import CatBanner from "@/base/core/components/CatBanner";

export default {
  name: "CategoryOverview",
  props: {
    category: Object,
    categories: Array,
  },
  components: { CatBanner },
  computed: {},
  data() {
    return {};
  },
};
</script>

